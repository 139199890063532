import styled from "styled-components";
import Styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  background: linear-gradient(
    to left bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.7)
  );
  @media screen and (max-width: 500px) {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
  }
`;

export const Text = styled.div`
  position: absolute;
  text-shadow: 1px 1px 3px #000000, 0px 0px 0px rgba(0, 0, 0, 0.3);
  padding-bottom: 4%;
  color: #f8f8f8;
  h2 {
    margin: 0 0 -5px 15px;
    text-align:center;
  }
  h1 {
    text-transform: uppercase;
    margin: -50px 0;
    padding: 20px 0;
    font-size: 7em !important;
    filter: drop-shadow(2px 4px 6px black);
    margin: -20px 0;
    padding: 20px 0 !important;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  p {
    text-decoration: none;
    color: #f8f8f8;
    margin: 0;
    font-size: 1.2 vw;
    width: 100%;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  @media only screen and (max-width: 800px) and (orientation: landscape){
    width: 80%;
    h1 {
      width: 96%;
      margin: auto;
      font-size: 10vw !important;
      overflow: hidden;
    }
    p {
      margin:auto;
      font-size:1vw;
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) and (orientation: portrait){
    width: 100%;
    margin: auto;
    position: absolute;
    padding: 0;
    top:31vh;
    text-align: center;
    h1 {
      width: 96%;
      margin: auto;
      font-size: 12vw !important;
      overflow: hidden;
    }
    p {
      margin:auto;
      font-size:1vw;
    }
    div{
      margin:0;
    }
  }
  @media only screen and (min-width: 500px) and (max-width: 1200px) and (orientation: portrait) {
    width: 100%;
    margin: auto;
    padding: 0;
    h1 {
      margin: 1% 2.5%;
      font-size: 6.5em !important;
    }
    p {
      font-size: 1vw;
      width: 90%;
      margin: 1% 2.5%;
      display: none;
    }
    div{
      margin:0;
    }
  }
`;

export const FlexMeeting2 = styled.div`
  height: 100%;
  width: 50%;

  div {
    height: 50%;
    width: 100%;
    cursor: pointer;
    div {
      width: 100%;
      height: 100%;
      transition-duration: 0.4s;
      text-align:start;

      :hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
      h2 {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 2.2em;
        width:90%;
        margin:30px;
        color: #f8f8f8;
        text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
        span {
            width:100%;
            display:block;
            font-size: 1em;
          }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 200px;
    min-height:367px;
    a {
      div {
      margin-left:0px;
        h2 {
          font-size: 1.3em;
          width:90%;
        }
      }
    }
  }
`;

export const FlexMeeting = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;

  margin:${props => (props.page === "index" ? "1% auto 0 auto" : null) } !important;
   /* HERO IMAGE ADJUST FOR NORMAL DSKTOP */
  @media screen and (min-width: 1600px) {
    height: 92vh;
  }
  a {
    height: 100%;
    width: 50%;
    .field1 {
      height: 100%;
      width: 100%;
      cursor: pointer;

      div {
        height: 100%;
        width: 100%;
        transition-duration: 0.4s;
        text-align:start;

        :hover {
          background-color: rgba(0, 0, 0, 0.3);
        }

        h2 {
          width: 90%;

          margin: 30px;
          color: #f8f8f8;
          position: absolute;
          text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
          bottom: 0;
          left: 0;
          font-size: 2.2em;
          span {
            display:block;
            font-size: 1em;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    height: auto;

    a {
      width: 100%;
      height: 400px;
      .field1 {

        div {
          h2 {
            font-size: 1.5em;
          }
        }
      }
    }
  }
`;

export const MeetingHeader = Styled.div`
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5% auto 5% auto;
    img{
        width: auto;
        height: auto;
        max-height: 220px;
        margin: 0;
        
        @media(max-width: 699px){
            width: 90%;
            margin: 10% auto 0 auto;
        }
    }
    p {
        margin: 10px 0 20px 0;
    }
`;
