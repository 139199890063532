import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  background: linear-gradient(
    to left bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.7)
  );

  @media screen and (max-width: 500px) {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
  }
`;

export const Text = styled.div`
  position: absolute;
  text-shadow: 1px 1px 3px #000000, 0px 0px 0px rgba(0, 0, 0, 0.3);
  width: 70%;
  padding-left: 2%;
  padding-bottom: 20px;
  color: #f8f8f8;
  
  h2 {
    margin: 0 0 -5px 15px;
  }
  
  h1 {
    text-transform: uppercase;
    margin: -20px 0;
    padding: 20px 0;
    font-size: 7em !important;
    filter: drop-shadow(2px 4px 6px black);
    margin: -20px 0;
    padding: 20px 0 !important;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  
  p {
    text-decoration: none;
    color: #f8f8f8;
    margin: 0;
    font-size: 1.2 vw;
    width: 85%;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  
  @media only screen and (max-width: 800px) and (orientation: landscape){
    width: 80%;
    padding: 20px;
    
    h1 {
      width: 96%;
      margin: auto;
      font-size: 10vw !important;
      overflow: hidden;
    }
    
    p {
      margin:auto;
      font-size:1vw;
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) and (orientation: portrait){
    width: 100%;
    margin: auto;
    position: absolute;
    padding: 0;
    text-align: left;
    padding: 15px;
    
    h1 {
      width: 96%;
      margin: auto;
      font-size: 12vw !important;
      overflow: hidden;
    }
    
    p {
      margin:auto;
      font-size:1vw;
    }
  }
  
  @media only screen and (min-width: 500px) and (max-width: 1200px) and (orientation: portrait) {
    width: 100%;
    margin: auto;
    padding: 20px;
    
    h1 {
      margin: 1% 2.5%;
      font-size: 6.5em !important;
    }
    
    p {
      font-size: 1vw;
      width: 90%;
      margin: 1% 2.5%;
      display: none;
    }
  }
`;

export const FlexMeeting2 = styled.div`
  height: 100%;
  width: 50%;

  div {
    height: 50%;
    width: 100%;
    cursor: pointer;

    div {
      width: 100%;
      height: 100%;
      transition-duration: 0.4s;
      text-align:start;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;  

      &.subtitle {
        width: auto;
        height: auto;
      }

      :hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
      h2 {
        bottom: 0;
        left: 0;
        font-size: 2.2em;
        width:80%;
        margin: 20px 20px 20px 0;
        color: #f8f8f8;
        text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
        span {
            display:block;
            font-size: 1em;
          }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 25vh;
    min-height:367px;

    a {
      div {
        h2 {
          font-size: 1.3em;
          width:90%;
        }
      }
    }
  }
`;

export const FlexMeeting = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1% auto 0 auto !important;

  @media screen and (min-width: 1600px) {
    height: 92vh;
  }
  a {
    height: 100%;
    width: 50%;
    .field1 {
      height: 100%;
      width: 100%;
      cursor: pointer;

      & > div {
        height: 100%;
        width: 100%;
        transition-duration: 0.4s;
        text-align:start;
        display: flex;
        align-items: flex-end;

        :hover {
          background-color: rgba(0, 0, 0, 0.3);
        }

        h2 {
          width: 90%;
          margin: 20px 20px 20px 0;
          color: #f8f8f8;
          text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
          bottom: 0;
          left: 0;
          font-size: 2.2em;
          span {
            display:block;
            font-size: 1em;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    height: auto;
    
    a {
      width: 100%;
      height: 50vh ;
      .field1 {
        
        div {
          h2 {
            font-size: 1.5em;
          }
        }
      }
    }
  }
`;

export const SectionDescription = styled.div`
  width: 80%;
  margin: 15px auto;
`