import styled from "styled-components";

export const FlexNewsHero = styled.div`
  display: flex;
  flex-direction: row;
  margin:${props => (props.page === "index" ? "1% auto 0 auto" : null) } !important;
   /* HERO IMAGE ADJUST FOR NORMAL DSKTOP */
  @media screen and (min-width: 1600px) {
    height: 92vh;
  }
  a {
    height: 100%;
    width: 50%;
    .field1 {
      height: 100%;
      width: 100%;
      cursor: pointer;

      div {
        height: 100%;
        width: 100%;
        transition-duration: 0.4s;
        text-align:start;

        :hover {
          background-color: rgba(0, 0, 0, 0.3);
        }

        h2 {
          width: 90%;
          margin: 0 0 20px 15px;
          color: #f8f8f8;
          position: absolute;
          text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
          bottom: 0;
          left: 0;
          font-size: 2.2em;
          span {
            display:block;
            font-size: 1em;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    height: auto;
    
    a {
      width: 100%;
      height: 50vh ;
      .field1 {
        
        div {
          h2 {
            font-size: 1.5em;
          }
        }
      }
    }
  }
`;

export const FlexNewsHero2 = styled.div`
  height: 100%;
  width: 50%;

  div {
    height: 50%;
    width: 100%;
    cursor: pointer;

    div {
      width: 100%;
      height: 100%;
      transition-duration: 0.4s;
      text-align:start;

      :hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
      h2 {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 2.2em;
        width:80%;
        margin: 0 0 20px 15px;
        color: #f8f8f8;
        text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
        span {
            display:block;
            font-size: 1em;
          }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 25vh;
    min-height:367px;

    a {
      div {
        h2 {
          font-size: 1.3em;
          width:90%;
        }
      }
    }
  }
`;

export const RestOfNewsFlex = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  margin: 1% auto 0 auto;
  a {
    width: 33%;
    .underNews {
      height: 35vh;
     

      div {
        height: 100%;
        display: flex;
        cursor: pointer;
        transition: 0.3s linear;

        :hover {
          background-color: rgba(0, 0, 0, 0.3);
        }

        h4 {
          width: 100%;
          margin: auto;
          color: #f8f8f8;
          font-size: ${props => (props.page === "index" ? "3em" : "1.5vw")};
          text-align: center;
          text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    display: ${props => (props.page === "index" ? "flex" : "none")};
    flex-direction: column;
    width: 100%;
    height: auto;
    

    a {
      width: 100%;
      
      padding: 3% 3% 0 3%;
      .underNews {
        height: 33vh;
        min-height:250px;
      }
    }
  }

`;

export const AllNewsText = styled.div`
  width: 98%;
  font-size: 20px;
  a {
    width: 100%;
    div {
      height: 7vh;
      width: 100%;
      color: black;
      float: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      p {
        margin: 0 !important;
        font-family: StureplansgruppenUnderRubrik;
        :hover {
          text-decoration: underline;
        }
      }
      svg {
        margin-left: 10px;
      }
    }
  }
`;

//THIS COMPONENT GOES TO PAGE "NEWSALL"

export const AllNews = styled.div`
  width: 100%;
  margin-top:110px;
  display: flex;
  overflow:hidden;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .allNews {
    min-width: 420px;
    min-height: 330x;
    width: 32.2vw;
    height: 40vh;
    margin: 0 5px 5px 0;
    a :hover {
      text-decoration: none;
    }
    div {
      color: white;
      -webkit-transition-duration: 0.4s; /* Safari */
      transition-duration: 0.4s;
      text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
      height: 100%;
      width: 100%;
      align-items: center;
      text-align: center;
      display: flex;
      justify-content: center;
      :hover {
        background-color: rgba(0, 0, 0, 0.4);
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
          0 17px 50px 0 rgba(0, 0, 0, 0.19);
      }
      h3 {
        width: 80%;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    width:98%;
    margin: 60px auto 0 auto;
    .allNews {
      min-width: 0;
      width: 100%;
      margin: 0 0 5px 0;
    }
  }
`;

export const ShowMore  = styled.div`
  width: 100%;
  display:flex;
  margin-top: 1%;
  justify-content: center;
  .arrowDown{
    cursor: pointer;
    font-size: 2em;
  }

`
