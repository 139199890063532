import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { parseOptions } from "../utils/parseOptions";
import { BokaBordSingle } from "../css/templateStyle";

import bokabord from "../assets/icons/boka.png";
import * as MeetingStyle from "../css/firstThreeMeetingStyle";

export const FirstThreeMeetings = ({ data, english, onModalOpen }) => {
  return (
    <>
      {data && data.title && (
        <h2>{data.title}</h2>
      )}
      {data && data.description && (
        <MeetingStyle.SectionDescription>
          {documentToReactComponents(data.description.json, parseOptions)}
        </MeetingStyle.SectionDescription>
      )}

      <BokaBordSingle>
        <div className="buttonContainer">
          <button
            className={"waiteraid-widget textDecorationNone"}
            href=""
            target="_blank"
            rel="noopener noreferrer"
            onClick={onModalOpen}
          >
            <img src={bokabord} alt="book table" />
            <div>
              <p>{english ? "RESERVATION" : "BOKA LOKAL"}</p>
            </div>
          </button>
        </div>
      </BokaBordSingle>

      <MeetingStyle.FlexMeeting className="hero" page="page">
        {data?.[0] && (
          <Link
            className="textDecorationNone"
            to={`/news/${data[0].node.slug || ''}`}
          >
            <BackgroundImage
              Tag="div"
              className={"field1"}
              fluid={data[0]?.node?.huvudBild?.fluid}
              backgroundColor={`#040e18`}
            >
              <div>
                <MeetingStyle.Text>
                  <h2>{data[0]?.node.rubrik}</h2>                  
                </MeetingStyle.Text>
              </div>
            </BackgroundImage>
          </Link>
        )}
        <MeetingStyle.FlexMeeting2>
          {data?.[1] && (
            <Link
              className="textDecorationNone"
              to={`/news/${data[1].node.slug || ''}`}
            >
              <BackgroundImage
                Tag="div"
                className={"field2"}
                fluid={data[1]?.node?.huvudBild?.fluid}
                backgroundColor={`#040e18`}
              >
                <div>
                  <MeetingStyle.Text>
                    <h2>{data[1]?.node.rubrik}</h2>  
                    {/* {documentToReactComponents(data.blocks[1].childContentfulBlockTextRichTextNode.json, parseOptions)} */}
                  </MeetingStyle.Text>
                </div>
              </BackgroundImage>
            </Link>
          )}

          {data?.[2] && (
            <Link
              className="textDecorationNone"
              to={`/news/${data[2].node.slug || ''}`}
            >
              <BackgroundImage
                Tag="div"
                className={"field2"}
                fluid={data[2]?.node?.huvudBild?.fluid}
                backgroundColor={`#040e18`}
              >
                <div>
                  <MeetingStyle.Text>
                    <h2>{data[2]?.node.rubrik}</h2>  
                    {/* {documentToReactComponents(data.blocks[2].childContentfulBlockTextRichTextNode.json, parseOptions)} */}
                  </MeetingStyle.Text>
                </div>
              </BackgroundImage>
            </Link>
          )}
        </MeetingStyle.FlexMeeting2>
      </MeetingStyle.FlexMeeting>
    </>
  );
};

export default FirstThreeMeetings;
