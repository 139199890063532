import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { parseOptions } from "../../utils/parseOptions";

import { RestOfNewsFlex } from "../../css/newsStyle";
import { FlexH1 } from "../../css/CardFlexStyle";
import { SectionDescription } from "../../css/firstThreeMeetingStyle"

export const Erbjudanden = ({ data }) => {
  return (
    <>
      {data && data.title && (
        <h2>{data.title}</h2>
      )}
      {data && data.description && (
        <SectionDescription>
          {documentToReactComponents(data.description.json, parseOptions)}
        </SectionDescription>
      )}
      <RestOfNewsFlex page="index">
        {data.blocks && data.blocks.map((item, number) => {
          const checkIfExternal = new RegExp('^(http|https)://')

          return !checkIfExternal.test(item.link) ? (
            <Link className="textDecorationNone" to={`${item.link || ''}`}>
              <BackgroundImage
                Tag="div"
                className={"underNews"}
                fluid={item.background.fluid}
              >
                <FlexH1>
                  {documentToReactComponents(data.blocks[number].childContentfulBlockTextRichTextNode.json, parseOptions)}
                </FlexH1>
              </BackgroundImage>
            </Link>
          ) : (
            <a className="textDecorationNone" target="_blank" href={item.link}>
              <BackgroundImage
                Tag="div"
                className={"underNews"}
                fluid={item.background.fluid}
              >
                <FlexH1>
                  {documentToReactComponents(data.blocks[number].childContentfulBlockTextRichTextNode.json, parseOptions)}
                </FlexH1>
              </BackgroundImage>
            </a>
          )
        })}
      </RestOfNewsFlex>
    </>
  );
};

export default Erbjudanden;
