import Styled from "styled-components";
import styled from "styled-components";

export const container = Styled.div`
    width: 80%;
    margin: auto;
    margin-bottom: 5%;
    background: inherit;
    @media(max-width: 992px){
        width: 99%;
    }
`;

export const ImgContainer = Styled.div`
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    @media(max-width: 1000px){
        justify-content: center;
        flex-direction: column;
        max-height: none;
    }
`;

export const MeetingSmall = Styled.div`
    width: 100%;
    height: 700px;
    margin:3px
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    color: #f8f8f8;

    a: hover {
        text-decoration: none;
    }

    .subtitle {
        color: #f8f8f8;
        width: auto;
        height: auto;
    }

    h1, h2, h3, h4 {
        width: auto;
        height: auto;
        left: 0;
        font-size: 2.2em;
        margin: 0 0 20px 15px;
        color: #f8f8f8;
        text-shadow: 2px 2px 8px #000000, 1px 1px 1px rgba(0, 0, 0, 0.9);
        span {
            display:block;
            font-size: 1em;
          }
      }
    .LogoImage{
        height: auto;
        width: 50%;
        min-width: 150px;
        max-width: 250px;
    }
    .videoLogo {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
    }
    }
    .SmallImage{
            width: 1000px;
            height: auto;
            margin:5px;
        }

    @media(max-width: 1000px){
        flex-direction: column;
        width: 90%;
        margin-left: 5%;
        margin-top: -2px;
    }

*{
    margin: 3px;
    width:100%;
    height:100%;
}
    .SmallImage{
        width: 99%;
        height: 100%;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
