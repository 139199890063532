import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { parseOptions } from "../../utils/parseOptions";

import weekend from "../../images/themeeting_logo.png";

import { Text, SectionDescription } from "../../css/firstThreeMeetingStyle";
import { MeetingHeader } from "../../css/meetingStyle";
import * as Styled from "../../css/theWeekendStyle";

const checkIfExternal = new RegExp('^(http|https)://')

export const NewsletterMeetings = ({ data }) => {
  const checkIfInternalReg = new RegExp('^(http|https)://')
  const isInternalLink = !checkIfInternalReg.test(data.mainLink)

  return (
    <Styled.container>
      <MeetingHeader>
        {isInternalLink && data.mainLink ? (
          <Link to={data.mainLink}>
            <img
              alt="the weekeed"
              src={weekend}
            />
          </Link> ) : (
          <a style={{ margin: '10px 0' }} target="_blank" href={data.mainLink}>
            <img
              alt="the weekeed"
              src={weekend}
            />
          </a>
        )}
      </MeetingHeader>
      {data && data.description && (
        <SectionDescription>
          {documentToReactComponents(data.description.json, parseOptions)}
        </SectionDescription>
      )}
      <Styled.ImgContainer>
        <Styled.WeekendLarge>
          {data.blocks && data.blocks[0] && (
            !checkIfExternal.test(data.blocks[0].link) ? (
              <Link
                className="textDecorationNone"
                to={`${data.blocks[0].link || ''}`}
              >
                {data.blocks[0].background.file.contentType === "video/mp4" ? (
                  <video loop autoPlay muted playsInline style={{ objectFit: "fill", height: "100%", width: "100%" }}>
                    <source src={data.blocks[0].background.file.url} type="video/mp4" />
                  </video>
                ) : (
                  <BackgroundImage
                    Tag="div"
                    className={"field1"}
                    fluid={data.blocks[0].background.fluid}
                    backgroundColor={`#040e18`}
                  >
                  <div>
                    <Text>
                      {documentToReactComponents(data.blocks[0].childContentfulBlockTextRichTextNode.json, parseOptions)}
                    </Text>
                  </div>
                </BackgroundImage>
                )}
              </Link>
            ) : (
              <a
                className="textDecorationNone"
                href={`${data.blocks[0].link || ''}`}
                target="_blank"
              >
                {data.blocks[0].background.file.contentType === "video/mp4" ? (
                  <video loop autoPlay muted playsInline style={{ objectFit: "fill", height: "100%", width: "100%" }}>
                    <source src={data.blocks[0].background.file.url} type="video/mp4" />
                  </video>
                ) : (
                  <BackgroundImage
                    Tag="div"
                    className={"field1"}
                    fluid={data.blocks[0].background.fluid}
                    backgroundColor={`#040e18`}
                  >
                  <div>
                    <Text>
                      {documentToReactComponents(data.blocks[0].childContentfulBlockTextRichTextNode.json, parseOptions)}
                    </Text>
                  </div>
                </BackgroundImage>
                )}
              </a>
            )
          )}
        </Styled.WeekendLarge>

        <Styled.WeekendSmall>
          {data.blocks && data.blocks[1] && (
            !checkIfExternal.test(data.blocks[1].link) ? (
              <Link
                className="textDecorationNone meeting-url"
                to={`${data.blocks[1].link || ''}`}
              >
                {data.blocks[1].background.file.contentType === "video/mp4" ? (
                  <video loop autoPlay muted playsInline style={{ objectFit: "fill", height: "50%", width: "100%" }}>
                    <source src={data.blocks[1].background.file.url} type="video/mp4" />
                  </video>
                ) : (
                  <Img
                    Tag="div"
                    className="SmallImage"
                    fluid={data.blocks[1].background.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  >
                  <div>
                    <Text>
                      {documentToReactComponents(data.blocks[1].childContentfulBlockTextRichTextNode.json, parseOptions)}
                    </Text>
                  </div>
                </Img>
                )}
              </Link>
            ) : (
              <a
                className="textDecorationNone meeting-url"
                href={`${data.blocks[1].link || ''}`}
                target="_blank"
              >
                {data.blocks[1].background.file.contentType === "video/mp4" ? (
                  <video loop autoPlay muted playsInline style={{ objectFit: "fill", height: "50%", width: "100%" }}>
                    <source src={data.blocks[1].background.file.url} type="video/mp4" />
                  </video>
                ) : (
                  <Img
                    Tag="div"
                    className="SmallImage"
                    fluid={data.blocks[1].background.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  >
                  <div>
                    <Text>
                      {documentToReactComponents(data.blocks[1].childContentfulBlockTextRichTextNode.json, parseOptions)}
                    </Text>
                  </div>
                </Img>
                )}
              </a>
            )
          )}

          {data.blocks && data.blocks[2] && (
             !checkIfExternal.test(data.blocks[2].link) ? (
              <Link
                className="textDecorationNone meeting-url"
                to={`${data.blocks[2].link || ''}`}
              >
                {data.blocks[2].background.file.contentType === "video/mp4" ? (
                  <video loop autoPlay muted playsInline style={{ objectFit: "fill", height: "50%", width: "100%" }}>
                    <source src={data.blocks[2].background.file.url} type="video/mp4" />
                  </video>
                ) : (
                  <Img
                    Tag="div"
                    className="SmallImage"
                    fluid={data.blocks[2].background.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  >
                    <div>
                      <Text>
                        {documentToReactComponents(data.blocks[2].childContentfulBlockTextRichTextNode.json, parseOptions)}
                      </Text>
                    </div>
                  </Img>
                )}
              </Link>
             ) : (
              <a
                className="textDecorationNone meeting-url"
                href={`${data.blocks[2].link || ''}`}
                target="_blank"
              >
                {data.blocks[2].background.file.contentType === "video/mp4" ? (
                  <video loop autoPlay muted playsInline style={{ objectFit: "fill", height: "50%", width: "100%" }}>
                    <source src={data.blocks[2].background.file.url} type="video/mp4" />
                  </video>
                ) : (
                  <Img
                    Tag="div"
                    className="SmallImage"
                    fluid={data.blocks[2].background.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  >
                    <div>
                      <Text>
                        {documentToReactComponents(data.blocks[2].childContentfulBlockTextRichTextNode.json, parseOptions)}
                      </Text>
                    </div>
                  </Img>
                )}
              </a>
            )
          )}
        </Styled.WeekendSmall>
      </Styled.ImgContainer>
    </Styled.container>
  );
};

export default NewsletterMeetings;
