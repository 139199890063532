import React, { useState } from "react";
import { useGlobal } from "reactn";
import { useStaticQuery, graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Modal from "react-modal";

import App from "../app";
import SEO from "../../components/seo";

import FirstThreeMeetings from "../../components/firstThreeMeetings";
import Erbjudanden from "../../components/event/erbjudanden";
import NewsletterMeetings from "../../components/event/newsletterMeetings";
import FourMeetings from "../../components/fourMeetings";
import getLang from "../../components/scripts/getLang";
import SmallTalkImage from "../../images/smalltalks.png";
import SmallTalkComponent from "../../components/smalltalk";
import { parseOptions } from "../../utils/parseOptions";

import * as Style from "../../css/templateStyle";
import bokabord from "../../assets/icons/boka.png";
import iconX from "../../images/icon-x.png";

import "./index.css";
import * as Global from "../../css/globalStyle";

Modal.setAppElement("#___gatsby");

const Event = () => {
  let [english] = useGlobal("English");

  const [modalIsOpen, setModalIsOpen] = useState(false);

  let hero = useStaticQuery(
    graphql`
      query {
        allContentfulNyheter(filter: {isEvent: {eq: true}}, sort: {order: DESC, fields: nyhetsDatum})  {
          edges {
            node {
              isEvent
              rubrik
              slug
              nyhetsDatum
              huvudBild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              node_locale        
            }
          }
        }
        allContentfulMeetings {
          edges {
            node {
              sverigesBstaLokaler {
                title
                description {
                  json
                }
                blocks {
                  background {
                    fluid(quality: 100) {
                      ...GatsbyContentfulFluid
                    }
                    title
                  }
                  childContentfulBlockTextRichTextNode {
                    json
                  }
                  link
                }
              }
              erbjudanden {
                title
                description {
                  json
                }
                blocks {
                  background {
                    fluid(quality: 100) {
                      ...GatsbyContentfulFluid
                    }
                    title
                  }
                  childContentfulBlockTextRichTextNode {
                    json
                  }
                  link
                }
              }
              husFrMten {
                title
                description {
                  json
                }
                blocks {
                  background {
                    fluid(quality: 100) {
                      ...GatsbyContentfulFluid
                    }
                    title
                    file {
                      url
                      contentType
                    }
                  }
                  childContentfulBlockTextRichTextNode {
                    json
                  }
                  link
                  showLogoInsteadOfText
                  logo {
                    fluid(quality: 100) {
                      ...GatsbyContentfulFluid
                    }
                  }
                }
              }
              newsletter {
                title
                description {
                  json
                }
                mainLink
                blocks {
                  background {
                    fluid(quality: 100) {
                      ...GatsbyContentfulFluid
                    }
                    title
                    file {
                      url
                      contentType
                    }
                  }
                  childContentfulBlockTextRichTextNode {
                    json
                  }
                  link
                  showLogoInsteadOfText
                  logo {
                    fluid(quality: 100) {
                      ...GatsbyContentfulFluid
                    }
                  }
                }
              }
              node_locale
              seoTitel
              seoDescription
              video {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
                file {
                  url
                }
              }
              smallTalksDescription {
                json
              }
            }
          }
        }
      }
    `
  );

  let meetingIndex = getLang(english, hero.allContentfulMeetings);

  let { video } = meetingIndex[0].node;
  let heroVideo = "video";

  heroVideo = "https:" + video.file.url;

  const latestNyheter = getLang(english, hero.allContentfulNyheter)
  console.log(latestNyheter)
  return (
    <App>
      {meetingIndex && meetingIndex[0] && (
        <SEO
          title={meetingIndex[0].node.seoTitel}
          description={meetingIndex[0].node.seoDescription}
        />
      )}

      <a className="textDecorationNone">
        <Global.BackgroundVideo>
          <video loop autoPlay muted playsInline>
            <source src={heroVideo} type="video/mp4" />
          </video>
        </Global.BackgroundVideo>
      </a>

      <Global.Topic>
        {latestNyheter && (
            <FirstThreeMeetings
              data={latestNyheter}
              english={english}
              onModalOpen={() => setModalIsOpen(true)}
            />
          )}
      </Global.Topic>

      <Global.Topic>
        {meetingIndex &&
          meetingIndex[0] &&
          meetingIndex[0].node &&
          meetingIndex[0].node.sverigesBstaLokaler && (
            <Erbjudanden
              data={meetingIndex[0].node.erbjudanden}
              english={english}
            />
          )}
      </Global.Topic>

      <Global.Topic>
        {meetingIndex &&
          meetingIndex[0] &&
          meetingIndex[0].node &&
          meetingIndex[0].node.newsletter && (
            <NewsletterMeetings
              data={meetingIndex[0].node.newsletter}
              english={english}
            />
          )}
      </Global.Topic>

      <Global.Topic>
        {meetingIndex &&
          meetingIndex[0] &&
          meetingIndex[0].node &&
          meetingIndex[0].node.husFrMten && (
            <FourMeetings
              english={english}
              data={meetingIndex[0].node.husFrMten}
            />
          )}
      </Global.Topic>

      <Global.CenterDiv>
        <img alt="Smalltalk" className="smallTalkImage" src={SmallTalkImage} />
        <div className="smallTalkText">
          {meetingIndex &&
            meetingIndex[0] &&
            meetingIndex[0].node.smallTalksDescription &&
            documentToReactComponents(
              meetingIndex[0].node.smallTalksDescription.json,
              parseOptions
            )}
        </div>
      </Global.CenterDiv>

      <SmallTalkComponent />

      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <button onClick={() => setModalIsOpen(false)}>
          <img src={iconX} />
        </button>

        <iframe
          src={`https://mpbookingengine.s3.eu-west-2.amazonaws.com/index.html?id=123459&lang=${
            english ? "en" : "sv"
          }`}
        ></iframe>
      </Modal>
    </App>
  );
};

export default Event;
