import Styled from "styled-components";

export const container = Styled.div`
    width: 80%;
    margin: auto;
    margin-bottom: 5%;
    background: inherit;
    @media(max-width: 992px){
        width: 99%;
    }
`;

export const WeekendHeader = Styled.div`
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5% auto 5% auto;
    img{
        width: 70%;
        height: auto;
        margin: 0;
        @media(max-width: 699px){
            width: 90%;
            margin: 10% 0 10% 0;
        }
    }
    p {
        margin: 10px 0 20px 0;
    }
`;
export const ImgContainer = Styled.div`
    display:flex;
    justify-content: space-between;
    @media(max-width: 1000px){
        justify-content: center;
        flex-direction: column;
        max-height: none;
    }
`;
export const WeekendLarge = Styled.div`
    height: auto;
    overflow: hidden;
    width: 54.5%;
    margin-top:5px;
    
    .LargeImage{
        width: 100%;
        height: 100%;
    }

    @media(max-width: 1000px){
        width: 90%;
        margin: auto;
    }

    .LargeImage{
        width: 100%;
        height: 100%;
    }
`;

export const WeekendSmall = Styled.div`
    width: 45%;
    height: auto;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;

    .SmallImage{
        width: 100% !important;
        height: auto;
        margin:auto;
    }

    @media(max-width: 1000px){
        width: 90%;
        margin: auto;
    }

    *{
        margin-top: 3px;
    }
    .SmallImage{
        width: 99%;
        height: 100%;
    }

    .meeting-url {
        height: 50%;

        & > div {
            height: 100%;
        }
    }
`;
