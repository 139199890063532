import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { parseOptions } from "../utils/parseOptions";

import * as StyledForMeeting from "../css/theMeetingStyle";
import { SectionDescription } from "../css/firstThreeMeetingStyle"
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";

const checkIfExternal = new RegExp('^(http|https)://')

const fourMeetings = ({ data }) => {
  return (
    <>
      {data && data.title && (
        <h2>{data.title}</h2>
      )}
       {data && data.description && (
        <SectionDescription>
          {documentToReactComponents(data.description.json, parseOptions)}
        </SectionDescription>
      )}
      <div>
        <StyledForMeeting.container>
          <StyledForMeeting.ImgContainer>
            <StyledForMeeting.MeetingSmall>
              {data.blocks && data.blocks[0] && (
                 !checkIfExternal.test(data.blocks[0].link) ? (
                  <Link
                    className="textDecorationNone withVideoLogo"
                    to={data.blocks[0].link || ''}
                  >
                    {data.blocks[0].background.file.contentType === "video/mp4" ? (
                      <>
                        <img alt="logo" className="LogoImage videoLogo" src={data.blocks[0].logo.fluid.src} />
                        <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                          <source src={data.blocks[0].background.file.url} type="video/mp4" />
                        </video>
                      </>
                    ) : (
                      <BackgroundImage
                        Tag="div"
                        className={"field1"}
                        fluid={data.blocks[0].background.fluid}
                        backgroundColor={`#040e18`}
                      >
                        <StyledForMeeting.ContentWrapper>
                          {data.blocks[0].showLogoInsteadOfText ? (
                            <img alt="logo" className="LogoImage" src={data.blocks[0].logo.fluid.src} />
                          ) : (
                            documentToReactComponents(data.blocks[0].childContentfulBlockTextRichTextNode.json, parseOptions)
                          )}
                        </StyledForMeeting.ContentWrapper>
                    </BackgroundImage>
                    )}
                  </Link>
                 ) : (
                  <a
                    className="textDecorationNone withVideoLogo"
                    href={data.blocks[0].link || ''}
                    target="_blank"
                  >
                    {data.blocks[0].background.file.contentType === "video/mp4" ? (
                      <>
                        <img alt="logo" className="LogoImage videoLogo" src={data.blocks[0].logo.fluid.src} />
                        <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                          <source src={data.blocks[0].background.file.url} type="video/mp4" />
                        </video>
                      </>
                    ) : (
                      <BackgroundImage
                        Tag="div"
                        className={"field1"}
                        fluid={data.blocks[0].background.fluid}
                        backgroundColor={`#040e18`}
                      >
                        <StyledForMeeting.ContentWrapper>
                          {data.blocks[0].showLogoInsteadOfText ? (
                            <img alt="logo" className="LogoImage" src={data.blocks[0].logo.fluid.src} />
                          ) : (
                            documentToReactComponents(data.blocks[0].childContentfulBlockTextRichTextNode.json, parseOptions)
                          )}
                        </StyledForMeeting.ContentWrapper>
                    </BackgroundImage>
                    )}
                  </a>
                 )
              )}

              {data.blocks && data.blocks[1] && (
                !checkIfExternal.test(data.blocks[1].link) ? (
                  <Link
                    className="textDecorationNone withVideoLogo"
                    to={data.blocks[1].link || ''}
                  >
                    {data.blocks[1].background.file.contentType === "video/mp4" ? (
                      <>
                        <img alt="logo" className="LogoImage videoLogo" src={data.blocks[1].logo.fluid.src} />
                        <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                          <source src={data.blocks[1].background.file.url} type="video/mp4" />
                        </video>
                      </>
                    ) : (
                      <BackgroundImage
                        Tag="div"
                        className={"field1"}
                        fluid={data.blocks[1].background.fluid}
                        backgroundColor={`#040e18`}
                      >
                        <StyledForMeeting.ContentWrapper>
                        {data.blocks[1].showLogoInsteadOfText ? (
                            <img alt="logo" className="LogoImage" src={data.blocks[1].logo.fluid.src} />
                        ) : (
                          documentToReactComponents(data.blocks[1].childContentfulBlockTextRichTextNode.json, parseOptions)
                        )}
                      </StyledForMeeting.ContentWrapper>
                    </BackgroundImage>
                    )}
                  </Link>
                ) : (
                  <a
                    className="textDecorationNone withVideoLogo"
                    href={data.blocks[1].link || ''}
                    target="_blank"
                  >
                    {data.blocks[1].background.file.contentType === "video/mp4" ? (
                      <>
                        <img alt="logo" className="LogoImage videoLogo" src={data.blocks[1].logo.fluid.src} />
                        <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                          <source src={data.blocks[1].background.file.url} type="video/mp4" />
                        </video>
                      </>
                    ) : (
                      <BackgroundImage
                        Tag="div"
                        className={"field1"}
                        fluid={data.blocks[1].background.fluid}
                        backgroundColor={`#040e18`}
                      >
                        <StyledForMeeting.ContentWrapper>
                        {data.blocks[1].showLogoInsteadOfText ? (
                            <img alt="logo" className="LogoImage" src={data.blocks[1].logo.fluid.src} />
                        ) : (
                          documentToReactComponents(data.blocks[1].childContentfulBlockTextRichTextNode.json, parseOptions)
                        )}
                      </StyledForMeeting.ContentWrapper>
                    </BackgroundImage>
                    )}
                  </a>
                )
              )}
            </StyledForMeeting.MeetingSmall>
           
            <StyledForMeeting.MeetingSmall>
            {data.blocks && data.blocks[2] && (
              !checkIfExternal.test(data.blocks[2].link) ? (
                <Link
                  className="textDecorationNone withVideoLogo"
                  to={data.blocks[2].link || ''}
                >
                  {data.blocks[2].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[2].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[2].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[2].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[2].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[2].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[2].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </Link>
              ) : (
                <a
                  className="textDecorationNone withVideoLogo"
                  href={data.blocks[2].link || ''}
                  target="_blank"
                >
                  {data.blocks[2].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[2].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[2].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[2].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[2].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[2].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[2].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </a>
              )
            )}

            {data.blocks && data.blocks[3] && (
              !checkIfExternal.test(data.blocks[3].link) ? (
                <Link
                  className="textDecorationNone withVideoLogo"
                  to={data.blocks[3].link || ''}
                >
                  {data.blocks[3].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[3].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[3].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[3].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[3].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[3].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[3].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </Link>
              ) : (
                <a
                  className="textDecorationNone withVideoLogo"
                  href={data.blocks[3].link || ''}
                  target="_blank"
                >
                  {data.blocks[3].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[3].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[3].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[3].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[3].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[3].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[3].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </a>
              )
              
            )}
            </StyledForMeeting.MeetingSmall>

             <StyledForMeeting.MeetingSmall>
            {data.blocks && data.blocks[4] && (
              !checkIfExternal.test(data.blocks[4].link) ? (
                <Link
                  className="textDecorationNone withVideoLogo"
                  to={data.blocks[4].link || ''}
                >
                  {data.blocks[4].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[4].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[4].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[4].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[4].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[4].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[4].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </Link>
              ) : (
                <a
                  className="textDecorationNone withVideoLogo"
                  href={data.blocks[4].link || ''}
                  target="_blank"
                >
                  {data.blocks[4].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[4].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[4].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[4].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[4].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[4].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[4].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </a>
              )
            )}

            {data.blocks && data.blocks[5] && (
              !checkIfExternal.test(data.blocks[5].link) ? (
                <Link
                  className="textDecorationNone withVideoLogo"
                  to={data.blocks[5].link || ''}
                >
                  {data.blocks[5].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[5].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[5].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[5].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[5].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[5].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[5].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </Link>
              ) : (
                <a
                  className="textDecorationNone withVideoLogo"
                  href={data.blocks[5].link || ''}
                  target="_blank"
                >
                  {data.blocks[5].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[5].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[5].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[5].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[5].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[5].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[5].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </a>
              )
              
            )}
            </StyledForMeeting.MeetingSmall>

              <StyledForMeeting.MeetingSmall>
            {data.blocks && data.blocks[6] && (
              !checkIfExternal.test(data.blocks[6].link) ? (
                <Link
                  className="textDecorationNone withVideoLogo"
                  to={data.blocks[6].link || ''}
                >
                  {data.blocks[6].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[6].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[6].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[6].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[6].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[6].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[6].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </Link>
              ) : (
                <a
                  className="textDecorationNone withVideoLogo"
                  href={data.blocks[6].link || ''}
                  target="_blank"
                >
                  {data.blocks[6].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[6].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[6].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[6].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[6].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[6].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[6].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </a>
              )
            )}

            {data.blocks && data.blocks[7] && (
              !checkIfExternal.test(data.blocks[7].link) ? (
                <Link
                  className="textDecorationNone withVideoLogo"
                  to={data.blocks[7].link || ''}
                >
                  {data.blocks[7].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[7].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[7].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[7].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[7].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[7].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[7].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </Link>
              ) : (
                <a
                  className="textDecorationNone withVideoLogo"
                  href={data.blocks[7].link || ''}
                  target="_blank"
                >
                  {data.blocks[7].background.file.contentType === "video/mp4" ? (
                    <>
                      <img alt="logo" className="LogoImage videoLogo" src={data.blocks[7].logo.fluid.src} />
                      <video loop autoPlay muted playsInline style={{ objectFit: "fill", width: "100%" }}>
                        <source src={data.blocks[7].background.file.url} type="video/mp4" />
                      </video>
                    </>
                  ) : (
                    <BackgroundImage
                      Tag="div"
                      className={"field1"}
                      fluid={data.blocks[7].background.fluid}
                      backgroundColor={`#040e18`}
                    >
                      <StyledForMeeting.ContentWrapper>
                      {data.blocks[7].showLogoInsteadOfText ? (
                          <img alt="logo" className="LogoImage" src={data.blocks[7].logo.fluid.src} />
                      ) : (
                        documentToReactComponents(data.blocks[7].childContentfulBlockTextRichTextNode.json, parseOptions)
                      )}
                    </StyledForMeeting.ContentWrapper>
                  </BackgroundImage>
                  )}
                </a>
              )
              
            )}
            </StyledForMeeting.MeetingSmall>
          
          </StyledForMeeting.ImgContainer>
        </StyledForMeeting.container>
      </div>
    </>
  );
};
export default fourMeetings;
